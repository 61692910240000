import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 1.5rem;
  h1 {
    font-family: "Prata", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 2rem;
    text-transform: initial;
    line-height: 4.0625rem;
    letter-spacing: 1px;
  }
  p {
    max-width: 60ch;
  }
  a[href^="tel:"] {
    color: #000;
    font-weight: 700;
  }
  .btn {
    background-color: #000;
    color: #fff;
  }
`

const Merci = () => {
  return (
    <main
      style={{
        backgroundColor: "#fae7e6",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SEO title="Merci!" />
      <Container>
        <h1>Merci!</h1>
        <p>
          Vous avez également la possibilité de remplir ce formulaire Vous
          recevez, immédiatement par mail, confirmation que le message a bien
          été transmis. Sans nouvelles de ma part dans 48h n’hésitez pas à me
          joindre par téléphone.
        </p>
        <p style={{ marginTop: "var(--medium)" }}>
          <a href="tel:0766802398">076 680 23 98</a>
        </p>
        <div style={{ marginTop: "var(--medium)" }}>
          <Link to="/" className="btn">
            revenir à l'accueil
          </Link>
        </div>
      </Container>
    </main>
  )
}

export default Merci
